<template>
    <v-footer
        tile
        flat
        height="90"
        class="font-weight-medium shadow-md"
        :color="getCompany.color_appbar || ($vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor)"
        :dark="$vuetify.theme.dark"
        :inset="true"
    >
        <v-col
            class="text-left py-0  ml-0 pl-0"
            cols="12"
        >
            <div class="d-flex flex-row justify-space-between align-center">
                <!--                <v-img
                    src="@/assets/img/logo-conecta.svg"
                    alt=""
                    max-width="120px"
                    max-height="84px"
                    class="flex-shrink-1"
                > </v-img>-->
                <language-selector />

                <div class="pl-2 flex-shrink-1 text-small">
                    <!--                    <div
                        class="flex-grow-1 mb-3"
                        style="min-width: 40px"
                    >
                        <p class="ma-0 text-right text-gray-700">
                            {{ getProgresssData.progressbarText }}
                        </p>
                        <v-progress-linear
                            :color="getProgresssData.progressColor"
                            :value="getProgresssData.progressValue"
                            rounded
                        />
                    </div>-->

                    <span
                        :style="`display: block;text-align: right; color:${getCompany.color_primary}`"
                        class="text-color-primary"
                    >
                        3 Weeks Consulting © {{ new Date().getFullYear() }}
                    </span>
                </div>
            </div>
        </v-col>
    </v-footer>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'Footer',
    components: {
        LanguageSelector: () => import('../../components/common/LanguageSelector.vue')
    },
    computed: {
        /* ...mapGetters('cache', ['getPostsOrdered', 'getCountPostsVisibles']), */
        ...mapGetters(['getThemeMode', 'getIndexShowPost', 'getCompany'])
        /* ...mapGetters("languages", ['getLang']) */
        /* ...mapGetters('configGeneral', ['getConfigKeyValue']), */
        /* getProgresssData() {
            const max = this.getPostsOrdered.length;
            const count = this.getCountPostsVisibles;

            const progressValue = Math.ceil(max > 0 ? count / max * 100 : 0);

            let progressColor = 'danger';
            if (progressValue >= 99) progressColor = 'secondary';
            else if (progressValue >= 85) progressColor = 'primary';

            let progressbarText = `${count} - ${max} `;
            progressbarText += this.$t('home.unlockedPercent', { percent: progressValue });

            return { progressValue, progressColor, progressbarText };
        } */
    }
}
</script>
